import {axiosInstance} from "../boot/axios";


export async function retrieveSystem() {
    return await axiosInstance.get("/api/retrieve_system")
}

export async function retrievePieData(period, serial, rfid, userId, dateFrom, dateTo, serial_num, type) {
    return await axiosInstance.get(`/api/get_data?period=${period}&serial=${serial}&rfid=${rfid}&userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}`)
}

export async function retrievePieDataSimpleUser(period, serial, rfid, userId, dateFrom, dateTo, serial_num, type) {
    return await axiosInstance.get(`/api/get_data_per_user?period=${period}&serial=${serial}&rfid=${rfid}&userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}`)
}

export async function retrieveBarData(type, serial) {
    return await axiosInstance.get(`/api/getbar_chart_per_type?type=${type}&serial=${serial}`)
}

export async function retrieveBarDataSimpleUser(type, serial) {
    return await axiosInstance.get(`/api/getbar_chart_per_user?type=${type}&serial=${serial}`)
}

export async function addSystem() {
    return await axiosInstance.post("/api/add_system")
}

export async function getSystemById(id) {
    return await axiosInstance.get(`/api/system_byId/${id}`)
}

export async function getSystemByPrefix(id) {
    return await axiosInstance.get(`/api/retrieve_by_prefix/${id}`)
}

export async function getHistoricalSystem(id) {
    return await axiosInstance.get(`/api/get_historical/${id}`)
}

export async function updateSystem(id, body) {
    return await axiosInstance.patch(`/api/system/${id}`, body)
}

export async function getSystemMarkers(id) {
    return await axiosInstance.get(`/api/get_markers/${id}`)
}

export async function getTableTotals(period, rfid, user, dateFrom, dateTo, serial_num, type) {
    return await axiosInstance.get(`/api/calculate_totals?period=${period}&rfid=${rfid}&userId=${user}&dateFrom=${dateFrom}&dateTo=${dateTo}&serial_num=${serial_num}&type=${type}`)
}
